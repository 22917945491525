import React, { useState ,useEffect } from "react";
import { red } from "@mui/material/colors";
import FormControl from "@mui/material/FormControl";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { Button, Input, Box, Unstable_Grid2 as Grid, Stack, IconButton ,Typography} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import AxiosCommon from "../axios/AxiosCommon";
import AxiosLoading from "../axios/AxiosLoading";
import LoadingButtonSend from "../button/LoadingButtonSend";
import LoadingButtonBase from "../button/LoadingButtonBase";
import ButtonLayout from "../layout/buttonlayout";
import TextFieldBase from "../../components/text/TextFieldBase";
import Resizer from "react-image-file-resizer";
import { isMobile } from 'react-device-detect';
import { useSelector } from "react-redux";


const FileUploadPopUp = (props) => {
  const [loading, setLoading] = useState(false);
  const [fileName, setfileName] = useState("");
  const [imgFile, setImgFile] = useState(null);	//파일
  const [files, setFiles] = useState([]);
  const [openPopUp, setOpenPopup] = useState(false);
  const [validation, setValidation] = useState({ fileName: "" })
  const axiosCommon = AxiosCommon();
  const companCd = useSelector((state) => state.Profile.data.company_cd);
  const shopCd = useSelector((state) => state.Profile.data.shop_cd);
  // useEffect(() => {
  //   if (openPopUp) return;
  //   setfileName("");
  //   setfileName([]);
  //   // setImgFile("C:\\rentking\files\A1001\business\gold_1694151791241.jpg");
  // }, [openPopUp])
  // let testImg = process.env.REACT_APP_API_HOST+"/common/file-upload/load/A1001/sample/4078488460_ce802c1003_z_1693970237147.jpg";
  // http://localhost:3000/api/common/file-upload/load/A1001/sample/4078488460_ce802c1003_z_1693970237147.jpg

  const handleFileUpload = async (e) => {
    //console.log("handleFileUpload", e);
    if (e.target.files) {
      // if (e.target.files[0].size > (512 * 1024)) {
      // if (e.target.files[0].size > (1024 * 1024 * 5)) {
      //   alert("2MB 이상 파일은 등록 할 수 없습니다.");
      //   return;
      // }
      // else 
      // if (e.target.files[0].type.indexOf("image") < 0) {
      //   alert("이미지 파일만 업로드 가능합니다.");
      //   return;
      // }
      if (e.target.files[0].size > (1024 * 1024 * 5)) {
        alert("5MB 이상 파일은 등록 할 수 없습니다.");
        return;
      }

      let event = e;
      let imgFile1 = new Array(1);
      try{
        imgFile1[0] = await resizeFile(e.target.files[0]);
      }
      catch(e){
        console.log(e);
      }
      event = {...event, target : {...e.target,files:imgFile1,"name":props.name}};
      setValidation({ ...validation, fileName: "" })
      setfileName(e.target.value);
      //setFiles(imgFile);
      props.onChange(event);
      // setOpenPopup(false);     
    }
  }
  const resizeFile = (file) => {
    // if (file["name"].length !== 0) {
    if (file["name"].length !== 0 && /\.(jpeg|jpg|png|gif)$/i.test(file.name)) {
      return new Promise((res) => {
        Resizer.imageFileResizer(
          file, // target file
          1000, // maxWidth
          1000, // maxHeight
          "WEBP", // compressFormat : Can be either JPEG, PNG or WEBP.
          70, // quality : 0 and 100. Used for the JPEG compression
          0, // rotation
          (uri) => {
            console.log("aaaa>>",uri);
             return res(uri)}, // responseUriFunc
          "file" // outputType : Can be either base64, blob or file.(Default type is base64)	
        );
      });
    }
    else{
      // return [{ name: "" }];
      return file; // 이미지 파일이 아니면 리사이즈하지 않고 그대로 반환
    }
  };
  const encodeFileToBase64 = async (fileBlob) => { 
    // if (fileBlob.size > (1024 * 1024 * 2)) {
    //   return;
    // }
    if (/\.(jpeg|jpg|png|gif)$/i.test(fileBlob.name)) {
      const reader = new FileReader(); 
      reader.readAsDataURL(await resizeFile(fileBlob));

      return new Promise((resolve) => { 
        reader.onload = () => 
          { 
            setImgFile(reader.result); 
              resolve();
          }; 
      });
    } else {
      setImgFile(null);
      return fileBlob;
    }
  };

  const onClick = (e) => {
    setOpenPopup(!openPopUp);
  }
  const onClickImageView = (e) => {
    window.open(props.url_ds, '_blank');
  }  
  const onCancel = (e) => {
    setfileName([]);
    setFiles("");
    setImgFile(null);
    props.onChange({ target: { value: "", name: props.name, files: [{ name: "" }] } });
    setOpenPopup(false);
  }
  const validationFunc = () => {
    let validateValue = {};
    //일자체크
    if (!fileName) {
      validateValue = { ...validateValue, fileName: "파일을 선택하세요." };
    }

    if (Object.keys(validateValue).length === 0) {
      return true;
    }
    else {
      setValidation(validateValue);
      return false;
    }
  };
  const doRetrieve = async () => {
    if (!validationFunc()) return;
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("sample", files[i]);
    }
    let jsonData = JSON.stringify({ company_cd: companCd, shop_cd: shopCd});
    formData.append("jsonData", jsonData);
    for (let key of formData.keys()) {
      console.log(key, ":", formData.get(key));
    }
    if (loading) return;
    setLoading(true);

    try {
      await axiosCommon
        .post("common/file-upload/upload", formData
          , {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          })
        .then((response) => {
          if (response.data?.code === 200) {
            console.log(response);
          }
        });
    } catch (error) {
      window.alert(error);
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    //if (!props.url_ds) return;
    // setImgFile("https://api.inbiz.co.kr/api/common/file-upload/load/A1001/business/gold_1694156017863.jpg");    
    if(openPopUp)
    setImgFile(props.url_ds);
    // eslint-disable-next-line
  }, [openPopUp])
  
  return (

    <FormControl
      sx={
        !props.disabled
          ? {
            m: 1,
            width: !props.width ? '170px' : props.width,
            "& .Mui-required ": { color: red[600] },
          }
          : {
            m: 1,
            width: props.width,
          }
      }
      id="fileUploadPopupFormControl"
      size="small"
      required={props.required}
      error={!props.validation ? false : true}
    >
      <Stack
        id="FileUploadStackHelp"
        direction="row"
        //spacing={-0.5} //돋보기간격
        spacing={0}
      //sx={{ height: 36 }}
      >
        <Typography 
          variant="h8"
          onClick={onClickImageView}
          sx={{
            cursor: props.value?'pointer':'', // 마우스 오버 시 손가락 모양으로 변경
            marginTop: '5px',
            maxWidth: isMobile?'250px':'550px',
            overflow: 'hidden', // 넘치는 텍스트를 감춤
            textOverflow: 'ellipsis', // 넘치는 텍스트를 '...'으로 표시 "..."
            whiteSpace: 'nowrap', // 줄 바꿈을 방지
          }}
        >
          {props.value} 
        </Typography>

        {/* <TextField
          // disabled={true}
          readOnly={true}
          error={!props.validation ? false : true}
          required={props.required}
          id={props.id}
          label={props.label}
          placeholder={props.placeholder}
          name={props.name}
          value={!props.value ? "" : props.value}
          variant={props.readOnly ? "filled" : "outlined"}
          autoComplete={props.autoComplete}
          size="small"
          InputLabelProps={{
            style: {
              fontSize: "13px",
              color: props.required ? red[600] : "#000000",
            },
          }}
          onClick={onClickImageView}
          helperText={props.validation}
          sx={
            props.required
              ? {
                m: 0,
                width: props.width,
                fontWeight: '900',
                "& .MuiInputLabel-root ": { color: red[600] },
                "& .MuiInputLabel-root .Mui-focused": { color: red[600] },
                "& .MuiOutlinedInput-root": {
                  height: "100%",
                  padding: "4px", // 여기에 원하는 padding 값을 설정
                  fontSize: props.fontSizeInput ? props.fontSizeInput : "14.5px",
                  cursor: "url('https://cdn.example.com/cursor-pointer.png'), pointer", // Hover 시에도 손가락 모양으로 유지
                },
              }
              : {
                m: 0,
                width: props.width,
                fontWeight: '900',
                "& .MuiOutlinedInput-root": {
                  height: "100%",
                  padding: "4px", // 여기에 원하는 padding 값을 설정
                  fontSize: props.fontSizeInput ? props.fontSizeInput : "14.5px",
                  cursor: "url('https://cdn.example.com/cursor-pointer.png'), pointer", // Hover 시에도 손가락 모양으로 유지
                },
              }
          }
        /> */}

        <IconButton
          disabled={props.disabled}
          color="primary"
          aria-label="upload picture"
          sx={{ m: 0, p: 0 }}
          onClick={onClick}
        >
          <UploadFileIcon sx={{ fontSize: 30 }} />{props.value ? '' : <span style={{ fontSize: '14px' }}>첨부파일</span>}   
        </IconButton>
      </Stack>
      <Dialog
        // fullScreen
        // fullWidth
        // maxWidth={"100"}
        open={openPopUp}
        //onClose={handleClose}
        // TransitionComponent={Transition}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              height:"100%",
              maxWidth: "500px",  // Set your width here
              maxHeight:"500px"
            },
          }
        }}
      >
        <DialogTitle></DialogTitle>
        <Box>
          {loading && <AxiosLoading />}
          {/* 버튼레이아웃 */}
          <ButtonLayout>
            {loading && <LoadingButtonSend
              label="파일저장"
              onClick={doRetrieve}
              loading={loading}
              variant="contained"
              name="BUTTON_SEARCH"
            />}
            <LoadingButtonBase
              autoFocus
              label="취소"
              onClick={onCancel}
              loading={loading}
              variant="contained"
              name="BUTTON_CLOSE"
            />
            <LoadingButtonBase
              autoFocus
              label="확인"
              onClick={onClick}
              loading={loading}
              variant="contained"
              name="BUTTON_CLOSE"
            />
          </ButtonLayout>
          <br></br>
          <Grid id="RegBottomLayout" container justifyContent="center" sx={{ backgroundColor: "white" }}>
            <Grid id="RegBottomLayout2" item xs={12} sx={{ width: "97%" }}>
              <Grid id="RegBottomLayout3"
                xs={12}
                container
                justifyContent="center"
                sx={{ width: "auto", height: "50px", mb: "20px" }}
              >
                <TextFieldBase
                  disabled={true}
                  label="" //label text
                  name={"fileName"} //바인딩변수
                  value={props.value} //화면의표시값
                  width={300.67} //컨퍼넌트넓이 default : 222.67
                  validation={validation["fileName"]}
                />
                <Button
                  component="label"
                  variant="outlined"
                  startIcon={<UploadFileIcon />}
                  sx={{ height: "36px", mt: "8px" }}
                >
                  파일찾기
                  <Input 
                    type="file"
                    style={{ display: 'none' }}
                    name={props.name}
                    // onChange={handleFileUpload}                    
                    onChange={(e) => {
                      encodeFileToBase64(e.target.files[0]);
                      handleFileUpload(e);
                    }} 
                    // inputProps={{ accept: "image/*" }}
                    inputProps={{
                      // 변경된 부분: 이미지 외 파일 형식도 지원하도록 accept 속성 수정
                      // accept: "image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    }}
                  />
                </Button>

                {/* <img src={testImg} alt="테스트"></img> */}
                
                {/* <div 
                  className="dropzoneFile"
                > 
                  {imgFile && 
                    <img 
                      src={imgFile} alt="preview_img" 
                      style={{
                        display:"flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "#efefef",
                        maxWidth: "420px",
                        maxHeight:"350px"
                      }}
                    />
                  } 
                </div>  */}

                <div
                  className="dropzoneFile"
                >
                  {/\.(jpeg|jpg|png|gif)$/.test(fileName) &&
                  // {true &&
                    <img
                      src={imgFile} alt="preview_img"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "#efefef",
                        maxWidth: "420px",
                        maxHeight: "350px"
                      }}
                    />
                  } 
                  
                  {/* //동영상 미리보기
                  {/\.(mp4|mov|avi|wmv)$/.test(fileName) && 
                    <video
                      controls
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "#efefef",
                        maxWidth: "420px",
                        maxHeight: "350px"
                      }}
                    >
                      <source src={URL.createObjectURL(imgFile)} type="video/mp4" />
                    </video>
                  } */}

                  {/* 이미지,동영상 아닐 경우 파일 이름만 표시 */}
                  {!imgFile && <Typography variant="body2">{props.value}</Typography>}
                </div>

              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    </FormControl>
  )
};

FileUploadPopUp.defaultProps = {
  required: false,
  width: 130,
  disabled: false,
};

export default FileUploadPopUp;
