import { Unstable_Grid2 as Grid, Card, CardContent } from "@mui/material";

const TopLayout = (props) => {
  return (
    <Grid xs={12} container 
      // justifyContent ="flex-start"
      justifyContent = {props.justifyContent ? "flex-start":props.justifyContent}
    >
      <Card
        variant="outlined"
        sx={{
          ml: props.ml, 
          marginRight:props.mr, 
          mt: props.mt, 
          mb: props.mb,  
          minWidth: "255px",
          width:"100%", 
          borderColor:props.borderColor?props.borderColor:'',
         }}
      >
        <CardContent 
          sx={{
            '&:last-child': { pb: "1px" },            
            fontSize: props.fontSize ? props.fontSize : "14px",
            paddingTop: props.paddingTop ? props.paddingTop : "10px",
            paddingLeft: props.paddingLeft ? props.paddingLeft : "5px",
            paddingRight: props.paddingRight ? props.paddingRight : "5px",
          }}
          >
          {props.children}
        </CardContent>
      </Card>
    </Grid>
  );
};
TopLayout.defaultProps = {
  mt: 2,
  mb: 2,
};
export default TopLayout;
