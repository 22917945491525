export const GRID_HEIGH = 50;
export const GRID_FONT_SIZE = '15px';


//그리드 로우 세로 라인
export const BORDER_WIDTH = '1px';
export const BORDER_STYLE = 'solid';
export const BORDER_COLOR = '#d4d4d4';

export const BORDER_RIGHT = `${BORDER_WIDTH} ${BORDER_STYLE} ${BORDER_COLOR} `;

//그리드 헤더 세로 라인.js
export const HEADER_BORDER_WIDTH = '1px';
export const HEADER_BORDER_STYLE = 'solid';
export const HEADER_BORDER_COLOR = 'red';

export const HEADER_BORDER_RIGHT = `${HEADER_BORDER_WIDTH} ${HEADER_BORDER_STYLE} ${HEADER_BORDER_COLOR}`;


