import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  TextField,
  ToggleButton,
} from "@mui/material";
import { useEffect, useState } from "react";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
// import SmartphoneIcon from "@mui/icons-material/Smartphone";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ChangeCircleOutlinedIcon from "@mui/icons-material/ChangeCircleOutlined";
import { IconLogout, IconCircleCheck, IconDeviceFloppy } from "@tabler/icons";
import { regexNumberCheck } from "../../../utils/Common";
import { useDispatch, useSelector } from "react-redux";
import { setLogout } from "../../../store/LoginSlice";
import { setProfile } from "../../../store/ProfileSlice";
import AxiosCommon from "../../../components/axios/AxiosCommon";
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';

const Profile = (props) => {
  const axiosCommon = AxiosCommon();
  const reduxProfile = useSelector((state) => state.Profile.data);
  const [loading, setLoading] = useState(false);
  const [showPassword, setshowPassword] = useState({
    passWord: false,
    confirm: false,
    changeWord: false,
  });
  const [reset, setreset] = useState({
    compCd: reduxProfile?.compCd,
    custId: reduxProfile?.userId,
    userNm: reduxProfile?.shopNm,
    hpNo1: reduxProfile?.hpNo1,
    hpNo2: reduxProfile?.hpNo2,
    hpNo3: reduxProfile?.hpNo3,
    passWd: "",
    confirm: "",
    changeWd: "",
  });
  const reduxDispatch = useDispatch();

  const handleClickShowPassword = (id) => {
    setshowPassword({ ...showPassword, [id]: !showPassword[id] });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleChange = (e, id) => {
    if (e.target.value === "" || regexNumberCheck(e.target.value)) {
      setreset({ ...reset, [id]: e.target.value });
    }
  };
  const logouthandleClick = () => {
    try {
      axiosCommon.delete("/pos/authentication/logout");
    } catch (error) {
      window.alert(error);
    } finally {
    }
    localStorage.clear();
    reduxDispatch(setProfile({}));
    reduxDispatch(setLogout());
    props.openHandleClick("close");
  };
  const onSubmited = (event) => {
    event.preventDefault();
    if (reset["changeWd"] !== reset["confirm"]) {
      alert("변경 비밀번호와 확인 비밀번호가 맞지않습니다.");
      return;
    }
    if (loading) return;
    setLoading(true);
    doRetrieve(reset);
  };

  /**Profile변경 api호출*/
  const doRetrieve = async (paramRepo) => {
    try {
      await axiosCommon
        .post("/pos/authentication/profile/update", paramRepo)
        .then((response) => {
          if (response.data?.code === "SAUTH00000") {
            reduxDispatch(setProfile(response.data.data));
            alert("저장되었습니다.");
            props.openHandleClick("ok");
          }
        });
    } catch (error) {
      window.alert(error);
    } finally {
      setLoading(false);
    }
  };

  //저장후 초기화
  useEffect(() => {
    setreset({
      ...reset,
      compCd: reduxProfile?.compCd,
      custId: reduxProfile?.userId,
      userNm: reduxProfile?.shopNm,
      hpNo1: reduxProfile?.hpNo1,
      hpNo2: reduxProfile?.hpNo2,
      hpNo3: reduxProfile?.hpNo3,
      passWd: "",
      confirm: "",
      changeWd: "",
    });
    // eslint-disable-next-line
  }, [reduxProfile]);

  return (
    <>
      <Dialog maxWidth="xs" fullWidth={true} open={props.open}>
        <DialogTitle>패스워드 변경</DialogTitle>
        <Divider sx={{ mt: 1, ml: 3, mr: 3, mb: 2 }} />
        <form onSubmit={onSubmited}>
          <DialogContent>
            <Box>
              <List sx={{ textAlign: "left" }}>
                <List
                  subheader={
                    <ListSubheader component="div" id="subheader">
                      PASSWORD
                    </ListSubheader>
                  }
                >
                  <ListItemButton
                    sx={{
                      borderRadius: `15px`,
                      ml: 2,
                      mr: 2,
                    }}
                    onClick={() => {}}
                  >
                    <ListItemIcon sx={{ my: "auto", minWidth: 36 }}>
                      <VpnKeyIcon fontSize="medium" sx={{ mr: 1 }} />
                    </ListItemIcon>
                    <ListItemText>
                      <TextField
                        required
                        id="password"
                        label="현재 비밀번호"
                        type={showPassword["passWord"] ? "text" : "password"}
                        fullWidth
                        size="small"
                        sx={{ marginBottom: 1 }}
                        value={reset["passWd"]}
                        onChange={(e) => handleChange(e, "passWd")}
                        autoComplete='off'
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={(e) =>
                                  handleClickShowPassword("passWord")
                                }
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword["passWord"] ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </ListItemText>
                  </ListItemButton>
                  <ListItemButton
                    sx={{
                      borderRadius: `15px`,
                      ml: 2,
                      mr: 2,
                    }}
                  >
                    <ListItemIcon sx={{ my: "auto", minWidth: 36 }}>
                      <ChangeCircleOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText>
                      <TextField
                        required
                        id="changeWd"
                        label="변경 비밀번호"
                        type={showPassword["changeWord"] ? "text" : "password"}
                        fullWidth
                        size="small"
                        sx={{ marginBottom: 1 }}
                        value={reset["changeWd"]}
                        onChange={(e) => handleChange(e, "changeWd")}
                        autoComplete='off'
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={(e) =>
                                  handleClickShowPassword("changeWord")
                                }
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword["changeWord"] ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </ListItemText>
                  </ListItemButton>
                  <ListItemButton
                    sx={{
                      borderRadius: `15px`,
                      ml: 2,
                      mr: 2,
                    }}
                  >
                    <ListItemIcon sx={{ my: "auto", minWidth: 36 }}>
                      <IconCircleCheck />
                    </ListItemIcon>
                    <ListItemText>
                      <TextField
                        required
                        id="confirm"
                        label="확인 비밀번호"
                        type={showPassword["confirm"] ? "text" : "password"}
                        fullWidth
                        size="small"
                        sx={{ marginBottom: 1 }}
                        value={reset["confirm"]}
                        onChange={(e) => handleChange(e, "confirm")}
                        autoComplete='off'
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={(e) =>
                                  handleClickShowPassword("confirm")
                                }
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword["confirm"] ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </ListItemText>
                  </ListItemButton>

                  {/* <ListItemButton
                    sx={{
                      borderRadius: `15px`,
                      ml: 2,
                      mr: 2,
                    }}
                    onClick={() => {}}
                  >
                    <ListItemIcon sx={{ my: "auto", minWidth: 36 }}>
                      <SmartphoneIcon fontSize="medium" sx={{ mr: 1 }} />
                    </ListItemIcon>
                    <ListItemText>
                      <TextField
                        required
                        id="hp1"
                        label="핸드폰"
                        size="small"
                        inputProps={{ maxLength: 3 }}
                        onChange={(e) => handleChange(e, "hpNo1")}
                        sx={{ mr: 1 }}
                        style={{ width: 90 }}
                        value={reset["hpNo1"]}
                      />
                      <TextField
                        required
                        id="hp2"
                        size="small"
                        inputProps={{ maxLength: 4 }}
                        onChange={(e) => handleChange(e, "hpNo2")}
                        sx={{ mr: 1 }}
                        style={{ width: 75 }}
                        value={reset["hpNo2"]}
                      />
                      <TextField
                        required
                        id="hp3"
                        size="small"
                        inputProps={{ maxLength: 4 }}
                        onChange={(e) => handleChange(e, "hpNo3")}
                        sx={{}}
                        style={{ width: 75 }}
                        value={reset["hpNo3"]}
                      />
                    </ListItemText>
                  </ListItemButton> */}
                </List>
              </List>
            </Box>
          </DialogContent>
          <DialogActions>
            <ToggleButton
              color="primary"
              value="check"
              type="submit"
              disabled={loading}
              sx={{ border: 0 }}
            >
              <IconDeviceFloppy fontSize="medium" sx={{ mr: 1 }} />
              변경하기
            </ToggleButton>
            {loading && (
              <CircularProgress
                size={50}
                sx={{
                  color: green[500],
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
            <ToggleButton
              color="primary"
              value="close"
              disabled={loading}
              sx={{ border: 0 }}
              onClick={logouthandleClick}
            >
              <IconLogout fontSize="medium" sx={{ mr: 1 }} />
              {" 닫기  "}
            </ToggleButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default Profile;
