import { 
  Typography,  
  Box ,
  // ToggleButton,
} from "@mui/material";
import "../../common/css/dashboard.css";
import { isMobile } from 'react-device-detect';
// import { IconDeviceDesktop} from "@tabler/icons";
import { useSelector } from "react-redux";

const HeaderDayDifference = (props) => {  
  const codeInfo = useSelector((state) => state.CodeInfo?.data).filter(params => params.codetype_cd === "SHOP");

  return (
    <Box 
    >
      <Typography 
        variant="body2"
        sx={{
          // cursor: 'pointer', // 마우스 오버 시 손가락 모양으로 변경
          maxWidth: isMobile?'100px':'300px',
          overflow: 'hidden', // 넘치는 텍스트를 감춤
          textOverflow: 'ellipsis', // 넘치는 텍스트를 '...'으로 표시 "..."
          whiteSpace: 'nowrap', // 줄 바꿈을 방지
          color: 'black', // 텍스트 색상     
        }}
      >                 
        {codeInfo[0].code_nm}
      </Typography>
      
    {/* </ToggleButton> */}
  </Box>
  );
};


export default HeaderDayDifference;
