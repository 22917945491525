import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Grid,
  Paper,
  Typography,
  TextField,  
  InputAdornment,
  IconButton,
  Divider,
  // Checkbox,
  // FormControlLabel,
} from "@mui/material";
// import Modal from 'react-modal';
import LoadingButton from "@mui/lab/LoadingButton";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
// import AccountCircle from "@mui/icons-material/AccountCircle";
// import VpnKeyIcon from "@mui/icons-material/VpnKey";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import AxiosCommon from "../../components/axios/AxiosCommon.js";
import ResetPassword from "./dialog/ResetPassword.js";
import { useDispatch, useSelector } from "react-redux";
import { setProfile } from "../../store/ProfileSlice.js";
import { setLogin } from "../../store/LoginSlice.js";
import { setCodeInfo } from "../../store/CodeInfoSlice.js";
import { setMenuInfo } from "../../store/MenuInfoSlice.js";
import ResetProfile from "./dialog/ResetProfile.js";
import TextFieldBase from "../../components/text/TextFieldBase.js";
import dayjs from "dayjs";
import { red } from "@mui/material/colors";
import Logo from "../../assets/logo.png"
import HeaderQuickMenu from "../common/header/HeaderQuickMenu.js";
import { isMobile } from 'react-device-detect';


// const customStyles = {
//   content: {
//     top: '50%',
//     left: '50%',
//     right: 'auto',
//     bottom: 'auto',
//     marginRight: '-50%',
//     transform: 'translate(-50%, -50%)',
//   },
// };

const Login = () => {
  const [username, setUsername] = useState(useSelector((state) => state.Profile?.data?.user_cd));
  const [password, setPassword] = useState("");
  const [shopCd, setShopCd] = useState(useSelector((state) => state.Profile?.data?.shop_cd));

  const [showPassword, setshowPassword] = useState({    
    passWord: false,
    confirm: false,
    changeWord: false,
  });
  const [loading, setLoading] = useState(false);
  const [loginstatus, setLoginstatus] = useState(false);
  const [resetPassword, setresetPassword] = useState(false);
  const [resetProfile, setresetProfile] = useState(false);
  const usedispatch = useDispatch();
  const navigate = useNavigate();
  const axiosCommon = AxiosCommon();
  const profile = useSelector((state)=>state.Profile?.data);
  const isLogged = useSelector((state) => state.Login?.value);

  // const [modalIsOpen, setIsOpen] = useState(false);
  // const [hideToday, setHideToday] = useState(false);
  // const [hideWeek, setHideWeek] = useState(false);
  
  // const closeModal = () => {
  //   if (hideToday) {
  //     const tomorrow = new Date();
  //     tomorrow.setDate(tomorrow.getDate() + 1);
  //     localStorage.setItem('hidePopupUntil', tomorrow);
  //   }
  //   if (hideWeek) {
  //     const nextWeek = new Date();
  //     nextWeek.setDate(nextWeek.getDate() + 7);
  //     localStorage.setItem('hidePopupUntil', nextWeek);
  //   }
  //   setIsOpen(false);
  // };

  //useEffect(() => {
  //   const hideUntil = localStorage.getItem('hidePopupUntil');
  //   if (!hideUntil || new Date(hideUntil) < new Date()) {
  //     setIsOpen(true);
  //   }
  // }, []);

  /**로그인 버튼이벤트*/
  const onSubmited = (event) => {
    event.preventDefault();
    let paramRepo = {
      company_cd: shopCd==='FTM'?shopCd:"SEYEON",
      // company_cd: shopCd,
      user_cd: username,
      user_password: password,
      shop_cd: shopCd,
    };
    doRetrieve(paramRepo);
  };

  /**로그인정보 api호출*/
  const doRetrieve = async (paramRepo) => {
    try {
      console.log(paramRepo);
      setLoading(true);
      await axiosCommon
        .post("/login/authentication/login", paramRepo)
        .then((response) => {
          if (response.data?.code === 200) {
            localStorage.setItem(
              "token",
              JSON.stringify({
                accessToken: response.data.data.token.accessToken,
                refreshToken: response.data.data.token.refreshToken
              })
            );
            usedispatch(setProfile(response.data.data.token));
            usedispatch(setLogin());
            usedispatch(setCodeInfo(response.data.data.code_info));
            usedispatch(setMenuInfo(response.data.data.menu_info));
            //let today = dayjs();
            //let lastChgDate = dayjs(response.data.data.lastChgDate).add(30,'day');
            //let lastChgDateCheck = dayjs(response.data.data.lastChgDate);
            //전화번호 미등록
            // if((!response.data.data.hpNo1
            //    ||!response.data.data.hpNo2
            //    ||!response.data.data.hpNo3)
            //    || lastChgDate <= today
            //    || lastChgDateCheck === "Invalid Date"){
            //   setresetProfile(true);
            // }
            // else{
              setLoginstatus(true);
            //}
          }
        });
    } catch (error) {
      window.alert(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (loginstatus) navigate("./rentmain");
  }, [loginstatus, navigate]);
  
  const handleClickShowPassword = (id) => {
    setshowPassword({ ...showPassword, [id]: !showPassword[id] });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  /**비밀번호초기화 팝업*/
  const openHandleClick = () => {
    setresetPassword(!resetPassword);
  };
  /**프로필변경 팝업*/
  const proFileopenHandleClick = (value) => {
    //프로필변경팝업에서 저장성공시 로그인
    if(value === "ok"){
      setLoginstatus(true);
    }
    setresetProfile(!resetProfile);
  }
  /**자동로그인 */
  useEffect(()=>{
    let today = dayjs().format("YYYYMMDD");
    let lastLogindt = profile?.lastLoginDt;
    //마지막로그인일자와 오늘일자 동일하면 자동로그인
    if(today===lastLogindt){
      setLoginstatus(true);
    }
    // eslint-disable-next-line
  },[isLogged])
  
  // const handleChange = (e, id) => {
  //   if (e.target.value === "" || regexNumberCheck(e.target.value)) {
  //     setreset({ ...reset, [id]: e.target.value });
  //   }
  // };
  return (
    <>
      <Paper style={{ backgroundImage: `url(${Image})` }}>
        <Grid
          container
          spacing={0}
          direction="row"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: "100vh" }}
        >
          <Box
            sx={{
              p: 7,
              border: 1,
              borderColor: "grey.300",
              width: "100%",
              maxWidth: '300px',
              borderRadius: "3px",
              backgroundColor: "white",
            }}
          >
            
            <Typography
              variant="h4"
              component="div"
              gutterBottom
              align="center"
            >
              {/* 로그인 */}
            </Typography>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >              
              <img src={Logo} alt="Berry" width="220" margin="30px" />
            </Box>
            <br />
            <form onSubmit={onSubmited}>
            <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {/* <AccountCircle
                  sx={{ color: "#404041", mr: 1, my: 0.5, fontSize: 40 }}
                /> */}
                <TextFieldBase
                  required
                  id="shopCd"
                  label="회사"
                  onChange={(e) => setShopCd(e.target.value)}
                  sx={{ marginBottom: 1, bgcolor:"#eff1ff", }}
                  autoComplete='off'
                  width='240px' 
                  value={shopCd}          
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {/* <AccountCircle
                  sx={{ color: "#404041", mr: 1, my: 0.5, fontSize: 40 }}
                /> */}
                <TextFieldBase
                  required
                  id="userId"
                  label="아이디"
                  onChange={(e) => setUsername(e.target.value)}
                  sx={{ marginBottom: 1, bgcolor:"#eff1ff", }}
                  autoComplete='off'
                  width='240px' 
                  value={username}          
                />
              </Box>
              {/* <br /> */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >                
                <TextField
                  required
                  id="password"
                  label="비밀번호"
                  type={
                    showPassword["passWord"] ? "text" : "password"
                  }
                  fullWidth
                  size="small"
                  sx={{ width:'240px', mt:'7px', 
                  }}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  autoComplete='off'                                    
                  InputLabelProps={{
                    style: {
                        fontSize:"13px",
                        color:red[600]//"#000000",
                      },
                  }}
                  InputProps={{
                    style: {
                      fontSize:"13px",
                      height:"35px",
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={(e) =>
                            handleClickShowPassword("passWord")
                          }
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword["passWord"] ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <br />

              <Divider sx={{ mt: 1, mb: 2 }} />

              <Box>                  
                <Grid container 
                  // spacing={1} 
                  direction="row"
                  justifyContent="space-evenly"
                  alignItems="center"
                >

                  {/* <LoadingButton
                    // color="secondary"
                    loading={loading}
                    loadingPosition="start"
                    startIcon={<VpnKeyIcon />}
                    // variant="contained"
                    type="button"
                    onClick={openHandleClick}
                    sx={{ 
                      color: "#7200be", 
                      bgcolor: "#fcf9fe", 
                      fontSize: 15,
                      width:'140px' ,
                      height:'38px'
                    }}
                  >
                    비밀번호변경
                  </LoadingButton>
                   */}
                  <LoadingButton
                    // color="secondary"
                    loading={loading}
                    loadingPosition="start"
                    startIcon={<EmojiPeopleIcon />}
                    variant="contained"
                    type="submit"
                    // sx={{ 
                    //   color: "#fff", 
                    //   bgcolor: "#2838e8", 
                    //   fontSize: 15 ,
                    //   width:'140px',
                    //   height:'38px'
                    // }}                    
                    sx={{ 
                      color: "#fff", 
                      bgcolor: "#1d4b75", 
                      fontSize: '15px',
                      width:'240px',
                      height:'38px',
                      '&:hover': {
                        // backgroundColor: '#5454c3',  // 마우스 오버 시 보라
                        backgroundColor: '#2180aa',  // 마우스 오버 시 군청       
                        // backgroundColor: '#2221aa',  // 마우스 오버 시 군청      
                      }
                    }}
                  >
                    Login
                  </LoadingButton>
                </Grid>
              </Box>
              

            </form>

            {/* <Divider sx={{ mt: 4, mb: 4 }} /> */}
            {/* <Box sx={{ margin: "1" }}> */}
              {/* <LoadingButton
                color="secondary"
                loading={loading}
                loadingPosition="start"
                startIcon={<VpnKeyIcon />}
                variant="contained"
                type="button"
                onClick={openHandleClick}
                fullWidth
                sx={{ color: "#fff", backgroundColor: "#807402", fontSize: 20 }}
              >
                패스워드 초기화
              </LoadingButton> */}

              <ResetPassword
                open={resetPassword}
                openHandleClick={openHandleClick}
              />
              <ResetProfile
                open={resetProfile}
                openHandleClick={proFileopenHandleClick}
              />
            {/* </Box> */}

          </Box>
          
          {!isMobile &&
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent:"right",
              alignItems: "center",
            }}
          >
            <HeaderQuickMenu/>
          </Box>}

        </Grid>   
        
      </Paper>

{/* 
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <h2>안녕하세요 환영합니다!</h2>
        <img src="https://via.placeholder.com/150" alt="Welcome"/>
        <FormControlLabel
          control={
            <Checkbox
              checked={hideToday}
              onChange={(e) => {
                setHideToday(e.target.checked);
                if (e.target.checked) setHideWeek(false);
              }}
            />
          }
          label="오늘 그만 보기"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={hideWeek}
              onChange={(e) => {
                setHideWeek(e.target.checked);
                if (e.target.checked) setHideToday(false);
              }}
            />
          }
          label="1주일 그만 보기"
        />
        <button onClick={closeModal}>확인</button>
      </Modal>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <h2>안녕하세요 환영합니다!</h2>
        <img src="https://via.placeholder.com/150" alt="Welcome"/>
        <FormControlLabel
          control={
            <Checkbox
              checked={hideToday}
              onChange={(e) => {
                setHideToday(e.target.checked);
                if (e.target.checked) setHideWeek(false);
              }}
            />
          }
          label="오늘 그만 보기"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={hideWeek}
              onChange={(e) => {
                setHideWeek(e.target.checked);
                if (e.target.checked) setHideToday(false);
              }}
            />
          }
          label="1주일 그만 보기"
        />
        <button onClick={closeModal}>확인</button>
      </Modal>
       */}

    </>
  );
};

export default Login;
