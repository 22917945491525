import { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Box,
} from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";
// import SmartphoneIcon from "@mui/icons-material/Smartphone";
// import { regexNumberCheck } from "../../../utils/Common";
import AxiosCommon from "../../../components/axios/AxiosCommon";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";

const ResetPassword = (props) => {
  const [reset, setreset] = useState({
    company_cd: "SEYEON",
    user_cd: "",
    shop_cd: "",
    hpNo1: "010",
    hpNo2: "",
    hpNo3: "",
  });
  const axiosCommon = AxiosCommon();
  const [loading, setLoading] = useState(false);
  const onSubmited = (e) => {
    e.preventDefault();
    if (loading) return;
    setLoading(true);
    doRetrieve(reset);
  };
  // const handleChange = (e, id) => {
  //   if (e.target.value === "" || regexNumberCheck(e.target.value)) {
  //     setreset({ ...reset, [id]: e.target.value });
  //   }
  // };
  useEffect(() => {
    setreset({
      ...reset,
      company_cd: "SEYEON",
      user_cd: "",
      shop_cd: "",
      hpNo1: "010",
      hpNo2: "",
      hpNo3: "",
    });
    // eslint-disable-next-line
  }, [props.open]);

  /**패스워드초기화 api호출*/
  const doRetrieve = async (paramRepo) => {
    try {
      await axiosCommon
        .post("common/aligo-api/password-send", paramRepo)
        .then((response) => {
          if (response.data?.code === 200) {
            // alert("등록된 핸드폰번호로 초기화패스워드가 발송되었습니다.");
            alert("비밀번호가 초기화 되었습니다.");
          }
        });
    } catch (error) {
      window.alert(error);
    } finally {
      setLoading(false);
      props.openHandleClick();
    }
  };

  return (
    <>
      <Dialog maxWidth="xs" fullWidth={true} open={props.open}>
        <DialogTitle >패스워드 초기화</DialogTitle>
        <Divider sx={{ mt: 1, ml: 3, mr: 3, mb: 2 }} />
        <form onSubmit={onSubmited}>
          <DialogContent>
          <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                mb: 1,
              }}
            >
              <AccountCircle
                sx={{ color: "action.active", mr: 1, my: 0.5, fontSize: 40 }}
              />
              <TextField
                required
                id="outlined-required"
                label="회사"
                onChange={(e) => setreset({ ...reset, shop_cd: e.target.value })}
                inputProps={{ maxLength: 10 }}
                fullWidth
                autoComplete='off'
                size="small"
                sx={{ marginBottom: 1 }}
                value={reset["shop_cd"]}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                mb: 1,
              }}
            >
              <AccountCircle
                sx={{ color: "action.active", mr: 1, my: 0.5, fontSize: 40 }}
              />
              <TextField
                required
                id="outlined-required"
                label="아이디"
                onChange={(e) => setreset({ ...reset, user_cd: e.target.value })}
                inputProps={{ maxLength: 10 }}
                fullWidth
                autoComplete='off'
                size="small"
                sx={{ marginBottom: 1 }}
                value={reset["user_cd"]}
              />
            </Box>

            {/* <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <SmartphoneIcon
                sx={{ color: "action.active", mr: 1, my: 0.5, fontSize: 40 }}
              />
              <TextField
                required
                id="outlined-required1"
                label="핸드폰번호"
                size="small"
                inputProps={{ maxLength: 3 }}
                onChange={(e) => handleChange(e, "hpNo1")}
                sx={{ mr: 1 }}
                autoComplete='off'
                value={reset["hpNo1"]}
              />
              <TextField
                required
                id="outlined-required2"
                size="small"
                inputProps={{ maxLength: 4 }}
                onChange={(e) => handleChange(e, "hpNo2")}
                sx={{ mr: 1 }}
                autoComplete='off'
                value={reset["hpNo2"]}
              />
              <TextField
                required
                id="outlined-required3"
                size="small"
                inputProps={{ maxLength: 4 }}
                onChange={(e) => handleChange(e, "hpNo3")}
                sx={{}}
                autoComplete='off'
                value={reset["hpNo3"]}
              />
            </Box> */}
          </DialogContent>
          <DialogActions>
            <Button type="submit" disabled={loading}>
              저장
            </Button>
            {loading && (
              <CircularProgress
                size={50}
                sx={{
                  color: green[500],
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
            <Button onClick={props.openHandleClick} disabled={loading}>
              닫기
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};
export default ResetPassword;
